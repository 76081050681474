import React, {useState} from 'react'

export class ScavengingLevel {
  static readonly LEVEL_1 = new ScavengingLevel('Level 1', 7.5, 7.5)
  static readonly LEVEL_2 = new ScavengingLevel('Level 2', 3, 10.5)
  static readonly LEVEL_3 = new ScavengingLevel('Level 3', 1.5, 12)
  static readonly LEVEL_4 = new ScavengingLevel('Level 4', 1, 13)

  private constructor(public readonly name: string, public readonly divider: number, public readonly sum: number) {
  }
}

export class ScavengingUnit {
  name: string
  total = 0

  constructor(name: string, total: number = 0) {
    this.name = name
    this.total = total
  }

  public getRequiredUnits(level: ScavengingLevel, max: ScavengingLevel): number {
    return level.divider / max.sum * this.total
  }

  public copyWithTotal(total: number): ScavengingUnit {
    return new ScavengingUnit(this.name, total);
  }
}

const RaubzugRechner = () => {

  const [enabledLevels, setEnabledLevels] = useState([ScavengingLevel.LEVEL_1, ScavengingLevel.LEVEL_2, ScavengingLevel.LEVEL_3, ScavengingLevel.LEVEL_4])

  const maxLevel = enabledLevels[enabledLevels.length - 1]

  const removeLevel = () => {
    return maxLevel === ScavengingLevel.LEVEL_1 ?
      undefined :
      setEnabledLevels(enabledLevels.slice(0, enabledLevels.length - 1))
  }

  const addLevel = () => {
    if (maxLevel === ScavengingLevel.LEVEL_4) {
    } else if (maxLevel === ScavengingLevel.LEVEL_3) {
      setEnabledLevels(levels => [...levels, ScavengingLevel.LEVEL_4])
    } else if (maxLevel === ScavengingLevel.LEVEL_2) {
      setEnabledLevels(levels => [...levels, ScavengingLevel.LEVEL_3])
    } else if (maxLevel === ScavengingLevel.LEVEL_1) {
      setEnabledLevels(levels => [...levels, ScavengingLevel.LEVEL_2])
    }
  }


  let units: ScavengingUnit[] = [
    new ScavengingUnit('Lancier'),
    new ScavengingUnit('Swordsman'),
    new ScavengingUnit('Archer'),
    new ScavengingUnit('Axe fighter'),
  ]

  return (<div>
    <label>Adding/Removing Levels: </label>
    <button onClick={addLevel}>+</button>
    <button onClick={removeLevel}>-</button>
    <table>
      <thead>
      <tr>
        <th>Name</th>
        {enabledLevels.map(level => <th>{level.name}</th>)}
        <th>Total</th>
      </tr>
      </thead>
      <tbody>
      {units.map(originalUnit => {
        const [unit, setUnit] = useState(originalUnit)
        return <tr>
          <td>{unit.name}</td>
          {enabledLevels.map(level =>
            <td>{unit.getRequiredUnits(level, maxLevel).toFixed(2)}</td>,
          )}
          <td><input value={unit.total} onChange={e => {
            setUnit(unit.copyWithTotal(+e.target.value))
          }} type='number' /></td>
        </tr>
      })}
      </tbody>
    </table>
  </div>)
}

export default RaubzugRechner
