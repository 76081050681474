import React from 'react'
import Layout from '../../layouts/layout'
import RaubzugRechner from '../../components/RaubzugRechner'

const TribalTools = () => {
  return (
    <Layout title={`Tribal Tools ⚔️`}>
      <section className={'section'}>
        <h1 className={'title'}>Tribal Tools ⚔</h1>
        <p>
          Der liebe Leo hat mich darum gebeten, den "Raubzugrechner" wieder online zu stellen. Den gab's auf meiner
          alten Seite und die hab ich ja weggeschmissen. Also. Ja. Hier kommt er wieder!
        </p>
        <p>
          Sorry, dass er bisschen crappy ist. Hab ihn mal fix wieder zusammengehackt. Viel Spaß!
        </p>
        <RaubzugRechner />
      </section>
    </Layout>
  )
}

export default TribalTools
